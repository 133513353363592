import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';


import {lighten} from '@mui/material/styles';
import {grey} from '@mui/material/colors';
import {DataGrid, esES, GridRenderCellParams} from '@mui/x-data-grid';
import {INSTITUTION_COLOR} from '../config';


//TYPES
export type Occurrency = {
  index: number,
  catalogNumber: string,
  institutionCode: string,
  basisOfRecord: string,
  scientificName: string,
  kingdom: string,
  phylum: string,
  class: string,
  order: string,
  family: string,
  genus: string,
  specificepithet: string,
}

export type TaxoTableProps = {
   data: Array<Occurrency>
};

const TaxoTable: FC<TaxoTableProps> = ({data}) => {
  
  const renderInstitution = (params: GridRenderCellParams) => {
    const chipColor = INSTITUTION_COLOR.filter(i => i.id === params.value)[0].color || '#d3d3d3';
    return params.value !== '' && 
            <Chip 
              label={params.value} 
              variant='outlined'
              sx={{color: grey[700], borderColor: chipColor, bgcolor: lighten(chipColor, 0.75), fontSize: 12, textTransform: 'uppercase'}}
            />;
  };
  const columns = [
    {
      field: 'catalogNumber',
      headerName: 'Nº CATÁLOGO',
      width: 160,
    },
    {
      field: 'institutionCode',
      headerName: 'INSTITUCIÓN',
      width: 250,
      renderCell: renderInstitution
    },
    {
      field: 'basisOfRecord',
      headerName: 'BASIS OF RECORD',
      width: 200,
    },
    {
      field: 'scientificName',
      headerName: 'NOMBRE CIENTÍFICO',
      width: 250
    },
    {
      field: 'kingdom',
      headerName: 'KINGDOM',
      width: 100,
    },
    {
      field: 'phylum',
      headerName: 'PHYLUM',
      width: 100,
    },
    {
      field: 'class',
      headerName: 'CLASS',
      width: 100,
    },
    {
      field: 'order',
      headerName: 'ORDER',
      width: 150,
    },
    {
      field: 'family',
      headerName: 'FAMILY',
      width: 150,
    },
    {
      field: 'specificepithet',
      headerName: 'SPECIFICE PITHET',
      width: 200,
    }
  ];


  return <Box sx={{height: '90vh'}}>
    <DataGrid
      sx={{
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: 'secondary.main',
          color: 'common.black',
          fontSize: '14px',
          fontWeight: 'bold',
          textAlign: 'center'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          textTransform: 'uppercase'
        },
        '& .MuiDataGrid-columnSeparator': {
          color: 'secondary.main',
        },
        '& .MuiButtonBase-root': {
          color: 'common.white'
        },
      }}
      rows={data}
      columns={columns}
      autoHeight={false}
      localeText={{
        ...esES.components.MuiDataGrid.defaultProps.localeText, // Extiende las traducciones en español
        noRowsLabel: 'Sin Ocurrencias', // Sobrescribe el texto para filas vacías
      }}
      //hideFooter
      getRowId={params => params.index}
    />
  </Box>;
};
export default TaxoTable;